import React from 'react';
import { KeyType } from 'react-relay/relay-hooks/helpers';

import { PicnicCss, Paginator, Box, LoadingPlaceholder } from '@attentive/picnic';
import { UsePaginationContext } from '../usePaginationContext';
import { ForwardPaginationArguments, GetTotalCount } from '../types';

const PaginatorLabelLoading: React.VFC<{ css?: PicnicCss }> = ({ css, ...rest }) => {
  return (
    <Box css={{ height: '$size5', width: '160px', ...css }} {...rest}>
      <LoadingPlaceholder css={{ height: '100%', width: '100%' }} />
    </Box>
  );
};

export const createPaginatorLabel = <
  FragmentType extends KeyType,
  QueryVariables extends ForwardPaginationArguments
>(
  usePaginationContext: UsePaginationContext<FragmentType, QueryVariables>,
  getTotalCount: GetTotalCount<FragmentType>
) => {
  type PaginatorLabelProps = { fragmentRef: FragmentType } & Omit<
    React.ComponentProps<typeof Paginator.Label>,
    'totalItems' | 'pageIndex' | 'itemsPerPage'
  >;

  const PaginatorLabelWithData: React.VFC<PaginatorLabelProps> = ({ fragmentRef, ...rest }) => {
    const { usePaginatedData, usePaginationControls } = usePaginationContext();

    const { data } = usePaginatedData(fragmentRef);
    const [
      {
        pageIndex,
        queryVariables: { first },
      },
    ] = usePaginationControls();

    const totalCount = getTotalCount(data);

    if (!first || totalCount === null) {
      return null;
    }

    return (
      <Paginator.Label
        totalItems={totalCount}
        pageIndex={pageIndex}
        itemsPerPage={first}
        {...rest}
      />
    );
  };

  const PaginatorLabelWithSuspense: React.VFC<PaginatorLabelProps> = ({ css, ...rest }) => {
    return (
      <React.Suspense fallback={<PaginatorLabelLoading css={css} />}>
        <PaginatorLabelWithData css={css} {...rest} />
      </React.Suspense>
    );
  };

  return PaginatorLabelWithSuspense;
};
