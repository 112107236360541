import { ReaderFragment, OperationType } from 'relay-runtime';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { Options } from 'react-relay/relay-hooks/useRefetchableFragmentNode';

import { createUsePaginatedData } from './usePaginatedData';
import { createPaginationControlsReducer } from './paginationControlsReducer';
import { createUsePaginationContext } from './usePaginationContext';
import { createPageSizeSelect, createPaginator } from './component-generators';
import { ForwardPaginationArguments, GetPageInfoFragment, GetTotalCount } from './types';
import { createPaginatorLabel } from './component-generators/createPaginatorLabel';

/**
 * This function, given a Relay query fragment, initializes utilities to support rendering the query data in a paginated manner.
 *
 * @param query A query fragment that
 *   - is on a connection that implements forward pagination arguments https://relay.dev/graphql/connections.htm#sec-Forward-pagination-arguments
 *   - includes the @refetchable directive https://relay.dev/docs/api-reference/graphql-and-directives/#refetchablequeryname-string
 *   - spreads usePaginatedDataFragment_pageInfo fragment into its pageInfo type.
 *
 * @param getPageInfoFragment A function which takes as argument the data returned by @param query,
 *   and returns the usePaginatedDataFragment_pageInfo fragment within it.
 *
 * @param getTotalCount (optional)  A function which takes as argument the data returned by @param query,
 *   and returns the totalCount value within it. If this argument is provided, the PaginatorLabel component will be enabled.
 *
 * @returns usePaginationContext: A hook which exposes two other hooks used to interact with the library:
 *           - usePaginatedData: A hook which returns the current page of data and pagination controls (hasNext, loadNext, hasPrevious, loadPrevious)
 *                ***NOTE: Any component calling this hook will suspend!***
 *           - usePaginationControls: Returns a reducer state and dispatch which can be used to set query variables, see PaginationControlsActionType.SET_VARIABLES.
 *
 *          PaginationContext: A set of components to enable usage of the library.
 *            - Provider: This must be rendered above any calls to the hooks above.
 *            - TestProvider: Used in a test context in place of the Provider to enable mocking.
 *            - Paginator, PaginatorLabel, PageSizeSelect,: Standardized pagiation control components wired up to this instance of paginated data.
 */
export const createPaginationContext = <
  RefetchableQueryType extends OperationType,
  FragmentType extends KeyType
>({
  query,
  options,
  getPageInfoFragment,
  getTotalCount,
}: {
  query: ReaderFragment;
  options?: Options;
  getPageInfoFragment: GetPageInfoFragment<FragmentType>;
  getTotalCount?: GetTotalCount<FragmentType>;
}) => {
  // Assume that the provided query conforms to graphql connection spec.
  type RefetchVariables = RefetchableQueryType['variables'] & ForwardPaginationArguments;

  const [usePaginationControls, PaginationControlsProvider] =
    createPaginationControlsReducer<RefetchVariables>();

  const usePaginatedData = createUsePaginatedData({
    query,
    options,
    getPageInfoFragment,
    usePaginationControls,
  });

  const { usePaginationContext, PaginationContextProvider, PaginationContextTestProvider } =
    createUsePaginationContext(usePaginatedData, usePaginationControls, PaginationControlsProvider);

  const Paginator = createPaginator(usePaginationContext);
  const PageSizeSelect = createPageSizeSelect(usePaginationContext);

  let PaginatorLabel;
  if (getTotalCount) {
    PaginatorLabel = createPaginatorLabel(usePaginationContext, getTotalCount);
  } else {
    PaginatorLabel = () => {
      throw new Error(
        'getTotalCount argument must be provided to createPaginationContext to use PaginatorLabel'
      );
    };
  }

  const PaginationContext = {
    Provider: PaginationContextProvider,
    TestProvider: PaginationContextTestProvider,
    Paginator,
    PaginatorLabel,
    PageSizeSelect,
  };

  return [usePaginationContext, PaginationContext] as const;
};
