import React from 'react';
import { ConcreteRequest, OperationType, VariablesOf } from 'relay-runtime';

import { compositeComponent } from '@attentive/picnic';

import { Presenter, PresenterProps } from './Presenter';

export type QueryContext = unknown | null;

const createQueryContext = <T extends OperationType | null>() => {
  // @ts-ignore
  const ctx = React.createContext<T['response']>(undefined);
  const useQueryContext = () => {
    const c = React.useContext(ctx);
    if (c === undefined) {
      throw new Error(
        "The Presenter's query data hook is not a child of the matching Presenter component"
      );
    }
    return c;
  };
  return [useQueryContext, ctx] as const; // 'as const' makes TypeScript infer a tuple
};

export interface WrappedPresenter<T extends OperationType>
  extends Omit<PresenterProps, 'gqlQuery'> {
  vars?: VariablesOf<T>;
}

/**
 *
 * @param query The gql query
 * @deprecated The Presenter pattern for gql is deprecated. Instead, it is encouraged to use vanilla Relay: https://relay.dev/docs/guided-tour/rendering/queries/
 */
export const createPresenter = <T extends OperationType>(query: ConcreteRequest) => {
  const [useQueryContext, ctx] = createQueryContext<T>();

  const PresenterComponent: React.FC<React.PropsWithChildren<WrappedPresenter<T>>> = ({
    vars,
    fallback,
    queryOptions,
    children,
  }) => {
    return (
      <Presenter
        vars={vars}
        gqlQuery={query}
        fallback={fallback}
        context={ctx}
        queryOptions={queryOptions}
      >
        {children}
      </Presenter>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PresenterProvider: React.FC<React.PropsWithChildren<{ queryData: any }>> = ({
    queryData,
    children,
  }) => {
    return <ctx.Provider value={queryData}>{children}</ctx.Provider>;
  };

  const WrappedPresenter = compositeComponent(PresenterComponent, {
    StoryProvider: PresenterProvider,
  });

  return [useQueryContext, WrappedPresenter] as const; // 'as const' makes TypeScript infer a tuple
};
