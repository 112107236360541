import _usePaginatedDataFragment_pageInfo from "./__generated__/usePaginatedDataFragment_pageInfo.graphql";
import { useEffect } from 'react';
import { useFragment, useRefetchableFragment } from 'react-relay';
import { graphql } from '../../relay-helpers';
import { PaginationControlsActionType } from './paginationControlsReducer';
import Fragment from './__generated__/usePaginatedDataFragment_pageInfo.graphql';
/**
 * This fragment should be spread into the pageInfo in the query used as argument for usePaginatedData.
 */

_usePaginatedDataFragment_pageInfo;

const usePaginatedData = _ref => {
  let {
    fragmentRef,
    query,
    options,
    getPageInfoFragment,
    usePaginationControls
  } = _ref;
  const [{
    queryVariables,
    pageCursors,
    pageIndex
  }, dispatch] = usePaginationControls();
  const [data, refetch] = useRefetchableFragment(query, fragmentRef);
  const currentCursor = pageCursors[pageIndex];
  const deepCompareVariables = JSON.stringify(queryVariables);
  useEffect(() => {
    refetch({ ...queryVariables,
      after: currentCursor
    }, options);
  }, [queryVariables, deepCompareVariables, currentCursor, options, refetch]);
  const pageInfoFragment = getPageInfoFragment(data);
  const pageInfoData = useFragment(Fragment, pageInfoFragment);
  return {
    data,
    hasNext: !!(pageInfoData !== null && pageInfoData !== void 0 && pageInfoData.hasNextPage),
    hasPrevious: pageIndex > 0,
    loadNext: () => dispatch({
      type: PaginationControlsActionType.LOAD_NEXT,
      currentCursor: pageInfoData === null || pageInfoData === void 0 ? void 0 : pageInfoData.endCursor
    }),
    loadPrevious: () => dispatch({
      type: PaginationControlsActionType.LOAD_PREVIOUS
    }),
    refetch: refetchOptions => {
      refetch({ ...queryVariables,
        after: currentCursor
      }, { ...options,
        ...refetchOptions
      });
    }
  };
};

/**
 * Providing all arguments except fragmentRef at initialization creates a streamlined API to
 * use in consuming components.
 */
export const createUsePaginatedData = args => {
  return fragmentRef => {
    return usePaginatedData({ ...args,
      fragmentRef
    });
  };
};