import { isObject } from '../.internal/isObject';

type AnyObject = Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

// extends AnyObject so we could use any string to access array properties
interface AnyArray extends ReadonlyArray<unknown>, AnyObject {}

type Sequence = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

type GetArrayValue<T extends readonly unknown[]> = T extends ReadonlyArray<infer U> ? U : never;

type IsTuple<T extends readonly unknown[]> = number extends T['length'] ? false : true;

type IsNumericKey<T extends string> = T extends `${number}` ? true : false;

type StringToPath<StringPath extends string> = StringToPath_<StringPath>;

type StringToPath_<
  StringPath extends string,
  Path extends string[] = []
> = StringPath extends `${infer Key}.${infer Rest}`
  ? StringToPath_<Rest, AppendPath<Path, Key>>
  : StringPath extends `[${infer Key}].${infer Rest}`
  ? StringToPath_<Rest, AppendPath<Path, Key>>
  : StringPath extends `[${infer Key}]${infer Rest}`
  ? StringToPath_<Rest, AppendPath<Path, Key>>
  : AppendPath<Path, StringPath>;

type AppendPath<Path extends string[], Item extends string> = Item extends ''
  ? Path
  : [...Path, Item];

export type Set<
  Obj extends AnyObject,
  StringPath extends string,
  Value
> = StringPath extends unknown ? Set_<Obj, StringToPath<StringPath>, Value> : never;

type Set_<Obj extends AnyObject, Path extends string[], Value, Index extends number = 0> = {
  0: Obj extends AnyArray
    ? SetArray<Obj, Path, Value, Index>
    : {
        [K in keyof Obj | Path[Index]]: K extends Path[Index]
          ? Set_<GetNextObject<Obj[K], Path[Sequence[Index]]>, Path, Value, Sequence[Index]>
          : Obj[K];
      };
  1: Path['length'] extends 0 ? Obj : Value;
}[Index extends Path['length'] ? 1 : 0];

type GetNextObject<Value, NextKey extends string> = [Value] extends [never]
  ? DefaultObject<NextKey>
  : Value extends AnyObject
  ? Value
  : DefaultObject<NextKey>;

type DefaultObject<Key extends string> = IsNumericKey<Key> extends true ? [] : {};

type SetArray<
  Arr extends AnyArray,
  Path extends string[],
  Value,
  Index extends number
> = IsNumericKey<Path[Index]> extends false
  ? Arr & Set_<{}, Path, Value, Index>
  : IsTuple<Arr> extends false
  ? Array<
      | GetArrayValue<Arr>
      | Set_<GetNextObject<GetArrayValue<Arr>, Path[Sequence[Index]]>, Path, Value, Sequence[Index]>
    >
  : SetTuple<
      Arr,
      Path[Index],
      Set_<GetNextObject<Arr[Path[Index]], Path[Sequence[Index]]>, Path, Value, Sequence[Index]>
    >;

type SetTuple<Arr extends AnyArray, Index extends string, Value> = SetTuple_<Arr, Index, Value>;

type SetTuple_<
  Arr extends AnyArray,
  Index extends string,
  Value,
  Result extends AnyArray = [],
  CurrentIndex extends number = Result['length']
> = {
  0: SetTuple_<Arr, Index, Value, [...Result, Arr[CurrentIndex]]>;
  1: [...Result, Value, ...GetTupleRest<Arr, Sequence[CurrentIndex]>];
}[`${CurrentIndex}` extends Index ? 1 : 0];

type GetTupleRest<
  Tuple extends AnyArray,
  Index extends number,
  Keys extends string = GetTupleKeys<Tuple>
> = `${Index}` extends Keys ? GetTupleRest_<Tuple, Index, Keys> : [];

type GetTupleRest_<
  Tuple extends AnyArray,
  Index extends number,
  Keys extends string,
  Result extends AnyArray = []
> = Tuple['length'] extends Index // 2
  ? Result
  : GetTupleRest_<Tuple, Sequence[Index], Keys, [...Result, Tuple[Index]]>;

type GetTupleKeys<Tuple extends AnyArray> = Extract<keyof Tuple, `${number}`>;

const getPathArray = <T extends string>(path: T) =>
  path.split(/[.[\]]/).filter(Boolean) as StringToPath<T>;

interface SetFunction {
  <Obj extends AnyObject, Path extends string, Value>(object: Obj, path: Path, value: Value): Set<
    Obj,
    Path,
    Value
  >;
}

export const set: SetFunction = (object, stringPath, value) => {
  const path = getPathArray(stringPath);
  const lastIndex = path.length - 1;
  let currentObject: AnyObject = object;

  for (let i = 0; i <= lastIndex; ++i) {
    const key = path[i];
    let newValue: unknown = value;

    if (i !== lastIndex) {
      const objValue = currentObject[key];
      const fallbackValue = !isNaN(+path[i + 1]) ? [] : {};
      newValue = isObject(objValue) || Array.isArray(objValue) ? objValue : fallbackValue;
    }
    (currentObject as AnyObject)[key] = newValue;
    currentObject = currentObject[key];
  }

  return object as any; // eslint-disable-line @typescript-eslint/no-explicit-any
};
