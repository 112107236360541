import React from 'react';

import { Box, LoadingIndicator, PicnicCss } from '@attentive/picnic';

const containerCss: PicnicCss = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignSelf: 'stretch',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$space2',
  width: '100%',
};

export const PresenterLoadingIndicator: React.VFC = () => {
  return (
    <Box css={containerCss} data-testid="presenter-loading-indicator">
      <LoadingIndicator />
    </Box>
  );
};
