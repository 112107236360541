export type DebounceOptions = {
  leading?: boolean;
  maxWait?: number;
  trailing?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce<T extends (...args: any) => any>(
  fn: T,
  timeoutDuration: number,
  opts: DebounceOptions = {}
): {
  (...args: Parameters<T>): ReturnType<T> | undefined;
  cancel(): void;
  flush(): ReturnType<T> | undefined;
} {
  const { leading = false, trailing = true, maxWait } = opts;

  let timerId: NodeJS.Timeout | undefined;
  let lastFnArgs: Array<Parameters<T>> | undefined;
  let result: ReturnType<T>;
  let lastCallTime = 0;
  let lastInvokeTime = 0;

  function invokeFunc(time: number) {
    const args = lastFnArgs || [];

    lastFnArgs = undefined;
    lastInvokeTime = time;

    result = fn(...args);
    return result;
  }

  function leadingEdge(time: number) {
    lastInvokeTime = time;

    timerId = setTimeout(timerExpired, timeoutDuration);

    return leading ? invokeFunc(time) : result;
  }

  function remainingTimeoutDuration(time: number) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;
    const remainingDuration = timeoutDuration - timeSinceLastCall;

    return maxWait === undefined
      ? remainingDuration
      : Math.min(remainingDuration, maxWait - timeSinceLastInvoke);
  }

  function shouldInvoke(time: number) {
    const timeSinceLastCall = time - lastCallTime;
    const timeSinceLastInvoke = time - lastInvokeTime;

    return (
      lastCallTime === 0 || // this is the initial call
      timeSinceLastCall >= timeoutDuration || // debounce timeout has been exceeded
      timeSinceLastCall < 0 || // system time has changed
      (maxWait !== undefined && timeSinceLastInvoke >= maxWait) // maxWait timeout has been exceeded
    );
  }

  function timerExpired() {
    const now = Date.now();

    if (shouldInvoke(now)) {
      return trailingEdge(now);
    }

    timerId = setTimeout(timerExpired, remainingTimeoutDuration(now));
  }

  function trailingEdge(time: number) {
    if (timerId) clearTimeout(timerId);
    timerId = undefined;

    if (trailing && lastFnArgs) {
      return invokeFunc(time);
    }

    lastFnArgs = undefined;
    return result;
  }

  function cancel() {
    if (timerId) {
      clearTimeout(timerId);
    }

    lastCallTime = 0;
    lastInvokeTime = 0;
    lastFnArgs = undefined;
    timerId = undefined;
  }

  function flush() {
    return !timerId ? result : trailingEdge(Date.now());
  }

  function debounced(...args: Array<Parameters<T>>) {
    const now = Date.now();
    const isInvoking = shouldInvoke(now);

    lastFnArgs = args;
    lastCallTime = now;

    if (isInvoking) {
      if (!timerId) {
        return leadingEdge(lastCallTime);
      }

      clearTimeout(timerId);
      timerId = setTimeout(timerExpired, timeoutDuration);

      return invokeFunc(lastCallTime);
    }

    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, timeoutDuration);
    }

    return result;
  }

  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
}

export { debounce };
