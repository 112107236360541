/* eslint-disable @typescript-eslint/no-explicit-any */
function omit(sourceObj: null | undefined, keys: string[]): {};
function omit<T extends object, K extends keyof T>(sourceObj: T, keys: K): Omit<T, K>;
function omit<T extends object, K extends keyof T>(sourceObj: T, keys: K[]): Omit<T, K>;
function omit<T extends object, K extends keyof T>(
  sourceObj: T | undefined | null,
  keys: K | K[] | any
): object {
  if (!sourceObj) return {};

  const keysToRemove = typeof keys === 'string' ? [keys] : keys;
  const objectKeys = Object.keys(sourceObj) as K[];

  return objectKeys.reduce((acc, key) => {
    if (keysToRemove.includes(key)) {
      return acc;
    }

    return {
      ...acc,
      [key]: sourceObj[key],
    };
  }, {} as Omit<T, (typeof keysToRemove)[number]>);
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export { omit };
