/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
// Lifted from https://github.com/lodash/lodash/blob/a67a085cc0612f5b83d78024e507427dab25ca2c/src/defaults.ts

/** Used for built-in method references. */
const objectProto = Object.prototype;

/** Used to check objects for own properties. */
const hasOwnProperty = objectProto.hasOwnProperty;

/**
 * Assigns own enumerable properties of source object(s) to the destination object for all destination
 * properties that resolve to undefined. Once a property is set, additional values of the same property are
 * ignored.
 *
 * Note: This method mutates object.
 */
function defaults<TObject, TSource>(
  object: TObject,
  source: TSource
): NonNullable<TSource & TObject>;
/**
 * @see _.defaults
 */
function defaults<TObject, TSource1, TSource2>(
  object: TObject,
  source1: TSource1,
  source2: TSource2
): NonNullable<TSource2 & TSource1 & TObject>;
/**
 * @see _.defaults
 */
function defaults<TObject, TSource1, TSource2, TSource3>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3
): NonNullable<TSource3 & TSource2 & TSource1 & TObject>;
/**
 * @see _.defaults
 */
function defaults<TObject, TSource1, TSource2, TSource3, TSource4>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3,
  source4: TSource4
): NonNullable<TSource4 & TSource3 & TSource2 & TSource1 & TObject>;
/**
 * @see _.defaults
 */
function defaults<TObject>(object: TObject): NonNullable<TObject>;
/**
 * @see _.defaults
 */
function defaults(object: any, ...sources: any[]): any;

/**
 * Assigns own and inherited enumerable string keyed properties of source
 * objects to the destination object for all destination properties that
 * resolve to `undefined`. Source objects are applied from left to right.
 * Once a property is set, additional values of the same property are ignored.
 *
 * **Note:** This method mutates `object`.
 */
function defaults(object: any, ...sources: any[]) {
  object = Object(object);
  sources.forEach((source) => {
    if (source != null) {
      source = Object(source);
      for (const key in source) {
        const value = object[key];
        if (
          value === undefined ||
          (value === objectProto[key as keyof typeof objectProto] &&
            !hasOwnProperty.call(object, key))
        ) {
          object[key] = source[key];
        }
      }
    }
  });
  return object;
}

export { defaults };
