import React, { ReactNode, Suspense } from 'react';
import {
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import { ConcreteRequest, OperationType, Variables } from 'relay-runtime';
import { isEqual } from '@attentive/nodash';

import { PresenterLoadingIndicator } from './PresenterLoadingIndicator';
import type { QueryContext } from './presenter-utils';

interface SharedPresenterProps {
  gqlQuery: ConcreteRequest;
}

interface PresenterBodyProps extends SharedPresenterProps {
  queryReference: PreloadedQuery<OperationType>;
  context: React.Context<QueryContext>;
}

const PresenterBody: React.FC<React.PropsWithChildren<PresenterBodyProps>> = ({
  gqlQuery,
  queryReference,
  context,
  children,
}) => {
  const queryData = usePreloadedQuery(gqlQuery, queryReference);

  const { Provider } = context;

  return <Provider value={queryData as QueryContext}>{children}</Provider>;
};

export interface PresenterProps extends SharedPresenterProps {
  vars?: Variables;
  fallback?: ReactNode;
  queryOptions?: UseQueryLoaderLoadQueryOptions;
  initialQueryReference?: PreloadedQuery<OperationType>;
}

export const Presenter: React.FC<
  React.PropsWithChildren<PresenterProps & { context: React.Context<QueryContext> }>
> = ({
  gqlQuery,
  vars = {},
  fallback,
  context,
  queryOptions = {},
  initialQueryReference = null,
  children,
}) => {
  const [queryReference, loadData] = useQueryLoader(gqlQuery, initialQueryReference);
  const queryVarsRef = React.useRef<Variables>();
  const queryOptionsRef = React.useRef<UseQueryLoaderLoadQueryOptions>();

  React.useEffect(() => {
    if (!isEqual(queryVarsRef.current, vars) || !isEqual(queryOptionsRef.current, queryOptions)) {
      loadData(vars, queryOptions);
      queryVarsRef.current = vars;
      queryOptionsRef.current = queryOptions;
    }
  }, [loadData, vars, queryOptions]);

  const loading = fallback ?? <PresenterLoadingIndicator />;

  return (
    <Suspense fallback={loading}>
      {queryReference && (
        <PresenterBody queryReference={queryReference} gqlQuery={gqlQuery} context={context}>
          {children}
        </PresenterBody>
      )}
    </Suspense>
  );
};
