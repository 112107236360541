import React from 'react';
import { KeyType } from 'react-relay/relay-hooks/helpers';

import { PicnicCss, Paginator } from '@attentive/picnic';
import { UsePaginationContext } from '../usePaginationContext';

const PaginatorLoading: React.VFC<{ css?: PicnicCss }> = (props) => {
  return (
    <Paginator.ButtonGroup
      hasNext={false}
      hasPrevious={false}
      loadNext={() => {}}
      loadPrevious={() => {}}
      data-testid="paginator-loading"
      {...props}
    />
  );
};

export const createPaginator = <FragmentType extends KeyType, QueryVariables>(
  usePaginationContext: UsePaginationContext<FragmentType, QueryVariables>
) => {
  const PaginatorWithData: React.VFC<{ fragmentRef: FragmentType; css?: PicnicCss }> = ({
    fragmentRef,
    ...rest
  }) => {
    const { usePaginatedData } = usePaginationContext();
    const { hasNext, hasPrevious, loadNext, loadPrevious } = usePaginatedData(fragmentRef);

    return (
      <Paginator.ButtonGroup
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        loadNext={loadNext}
        loadPrevious={loadPrevious}
        {...rest}
      />
    );
  };

  const PaginatorWithSuspense: React.VFC<{ fragmentRef: FragmentType; css?: PicnicCss }> = ({
    css,
    ...rest
  }) => {
    return (
      <React.Suspense fallback={<PaginatorLoading css={css} />}>
        <PaginatorWithData css={css} {...rest} />
      </React.Suspense>
    );
  };

  return PaginatorWithSuspense;
};
