import React, { ComponentType } from 'react';
import { KeyType } from 'react-relay/relay-hooks/helpers';

import { UsePaginatedData } from './usePaginatedData';

import {
  PaginationControlsProviderProps,
  UsePaginationControls,
} from './paginationControlsReducer';

/**
 * The "PaginationContext" layer does not add any functionality, it exists to improve the testability of components using the library.
 * By forcing consumers to access the hooks exposed by the library (usePaginatedData and usePaginationControls) through a context,
 * it allows us to provide mocked versions of those hooks in a test or story.
 */

export type PaginationContextData<FragmentType extends KeyType, QueryVariables> = {
  usePaginatedData: UsePaginatedData<FragmentType>;
  usePaginationControls: UsePaginationControls<QueryVariables>;
};

export type UsePaginationContext<
  FragmentType extends KeyType,
  QueryVariables
> = () => PaginationContextData<FragmentType, QueryVariables>;

export const createUsePaginationContext = <FragmentType extends KeyType, QueryVariables>(
  usePaginatedData: UsePaginatedData<FragmentType>,
  usePaginationControls: UsePaginationControls<QueryVariables>,
  PaginationControlsProvider: ComponentType<
    React.PropsWithChildren<PaginationControlsProviderProps<QueryVariables>>
  >
) => {
  const PaginationContext = React.createContext<
    PaginationContextData<FragmentType, QueryVariables> | undefined
  >(undefined);

  const usePaginationContext: UsePaginationContext<FragmentType, QueryVariables> = () => {
    const contextValue = React.useContext(PaginationContext);
    if (contextValue === undefined) {
      throw new Error(
        'PaginationUtils hook is not a child of the matching PaginationContextProvider component'
      );
    }

    return contextValue;
  };

  const PaginationContextProvider: React.FC<
    React.PropsWithChildren<PaginationControlsProviderProps<QueryVariables>>
  > = ({ children, ...rest }) => {
    return (
      <PaginationControlsProvider {...rest}>
        <PaginationContext.Provider
          value={{
            usePaginatedData,
            usePaginationControls,
          }}
        >
          {children}
        </PaginationContext.Provider>
      </PaginationControlsProvider>
    );
  };

  /**
   * This component only exposes setting the "data" returned by the usePaginatedData hook, but could easily be
   * updated to allow the consumer to provide their own customized version of the entire hook. An example where
   * this might be useful would be testing a custom paginator component to ensure it calls "loadNext" after an interaction.
   */
  const PaginationContextTestProvider: React.FC<
    React.PropsWithChildren<{
      data?: FragmentType[' $data'];
      defaultVariables?: QueryVariables;
      shouldSuspend?: boolean;
    }>
  > = ({ data, children, shouldSuspend, defaultVariables = {} }) => {
    return (
      <PaginationControlsProvider defaultVariables={defaultVariables as QueryVariables}>
        <PaginationContext.Provider
          value={{
            usePaginatedData: () => {
              // The API is undocumented but you can trigger a suspense boundary by throwing a promise from a component within it.
              // https://stackoverflow.com/questions/59791769/what-is-the-react-official-position-for-throwing-promises-inside-render-function
              if (shouldSuspend) {
                throw new Promise(() => undefined);
              }

              return {
                data,
                hasNext: false,
                hasPrevious: false,
                loadNext: () => {},
                loadPrevious: () => {},
                refetch: () => {},
              };
            },
            usePaginationControls,
          }}
        >
          {children}
        </PaginationContext.Provider>
      </PaginationControlsProvider>
    );
  };

  return {
    usePaginationContext,
    PaginationContextProvider,
    PaginationContextTestProvider,
  };
};
