/* eslint-disable @typescript-eslint/no-explicit-any */
export function isEqual(
  first: any,
  second: any,
  comparator?: (first: any, second: any) => boolean | undefined | void
): boolean {
  const comparatorValue = comparator?.(first, second);
  if (typeof comparatorValue === 'boolean') return comparatorValue;

  if (first === second) return true;

  if (
    (first === undefined || second === undefined || first === null || second === null) &&
    (first || second)
  ) {
    return false;
  }

  const firstType = first?.constructor.name;
  const secondType = second?.constructor.name;

  if (firstType !== secondType) {
    return false;
  }

  if (firstType === 'Array') {
    if (first.length !== second.length) {
      return false;
    }
    let equal = true;
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i], comparator)) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  if (firstType === 'Object') {
    let equal = true;
    const fKeys = Object.keys(first);
    const sKeys = Object.keys(second);
    if (fKeys.length !== sKeys.length) {
      return false;
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (!isEqual(first[fKeys[i]], second[fKeys[i]], comparator)) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  return first === second;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
