import React, { useReducer } from 'react';

/**
 * This reducer implements bi-directional pagination in FE state, and is intended for use with data on a GraphQL connection which only supports forward pagination.
 * To do so we store the cursors for each query the FE makes moving forwards, to allow us to then move backwards to previously viewed pages.
 * Since this is implemented entirely in FE state, this approach DOES NOT support navigating initially to a certain page and then moving bi-directionally.
 *
 * It also stores the variables for the GraphQL Query, co-locating the variables and pagination state allows us to easily reset the current page index/cursors whenever the variables change.
 */

type PageCursors = [
  // The correct cursor value for the first page is actually the value "null".
  null,
  ...string[]
];

interface PaginationControls<QueryVariables> {
  queryVariables: QueryVariables;
  pageCursors: PageCursors;
  pageIndex: number;
}

export enum PaginationControlsActionType {
  SET_VARIABLES,
  LOAD_NEXT,
  LOAD_PREVIOUS,
  OVERRIDE_VARIABLES,
}

type Action<QueryVariables> =
  | {
      type: PaginationControlsActionType.SET_VARIABLES;
      variables: QueryVariables;
    }
  | {
      type: PaginationControlsActionType.OVERRIDE_VARIABLES;
      variables: Partial<QueryVariables>;
    }
  | {
      type: PaginationControlsActionType.LOAD_NEXT;
      currentCursor: string;
    }
  | {
      type: PaginationControlsActionType.LOAD_PREVIOUS;
    };

const initialState: PaginationControls<{}> = {
  queryVariables: {},
  pageCursors: [null],
  pageIndex: 0,
};

const paginationControlsReducer = <QueryVariables,>(
  state: PaginationControls<QueryVariables>,
  action: Action<QueryVariables>
): PaginationControls<QueryVariables> => {
  const { pageIndex, pageCursors } = state;
  const isLastCursor = pageIndex === pageCursors.length - 1;

  switch (action.type) {
    case PaginationControlsActionType.SET_VARIABLES:
      return {
        // Reset pagination controls to defaults when variables change.
        ...initialState,
        queryVariables: action.variables,
      };
    case PaginationControlsActionType.OVERRIDE_VARIABLES: {
      return {
        // Reset pagination controls to defaults when variables change.
        ...initialState,
        queryVariables: { ...state.queryVariables, ...action.variables },
      };
    }
    case PaginationControlsActionType.LOAD_NEXT:
      return {
        ...state,
        pageIndex: pageIndex + 1,
        pageCursors: [...pageCursors, ...(isLastCursor ? [action.currentCursor] : [])],
      };
    case PaginationControlsActionType.LOAD_PREVIOUS:
      return {
        ...state,
        pageIndex: pageIndex - 1,
      };
    default:
      return state;
  }
};

type PaginationControlsReducer<QueryVariables> = [
  PaginationControls<QueryVariables>,
  React.Dispatch<Action<QueryVariables>>
];

export type UsePaginationControls<QueryVariables> = () => PaginationControlsReducer<QueryVariables>;

export interface PaginationControlsProviderProps<QueryVariables> {
  defaultVariables: QueryVariables;
}

export const createPaginationControlsReducer = <QueryVariables extends {}>() => {
  const PaginationControlsContext = React.createContext<
    PaginationControlsReducer<QueryVariables> | undefined
  >(undefined);

  const usePaginationControlsContext = () => {
    const contextValue = React.useContext(PaginationControlsContext);
    if (contextValue === undefined) {
      throw new Error(
        'PaginationControlsReducer hook is not a child of the matching PaginationControlsProvider component'
      );
    }

    return contextValue;
  };

  const PaginationControlsProvider: React.FC<
    React.PropsWithChildren<PaginationControlsProviderProps<QueryVariables>>
  > = ({ children, defaultVariables }) => {
    const reducerControls = useReducer<
      React.Reducer<PaginationControls<QueryVariables>, Action<QueryVariables>>
    >(paginationControlsReducer, { ...initialState, queryVariables: defaultVariables });

    return (
      <PaginationControlsContext.Provider value={reducerControls}>
        {children}
      </PaginationControlsContext.Provider>
    );
  };

  return [usePaginationControlsContext, PaginationControlsProvider] as const;
};
