import React from 'react';
import { KeyType } from 'react-relay/relay-hooks/helpers';

import { Box, PicnicCss, Select, Text } from '@attentive/picnic';
import { PaginationControlsActionType } from '../paginationControlsReducer';
import { UsePaginationContext } from '../usePaginationContext';
import { ForwardPaginationArguments } from '../types';

type PageSizeOptions = number[];
interface PageSizeSelectProps {
  options: PageSizeOptions;
  value: number;
  onChange: (value: number) => void;
  css?: PicnicCss;
}

const PageSizeSelect: React.VFC<PageSizeSelectProps> = ({ options, value, onChange, css }) => {
  return (
    <Box css={{ display: 'flex', alignItems: 'center', ...css }}>
      <Text variant="caption">Rows per page</Text>
      <Box>
        <Select
          onChange={(newValue: number) => onChange(newValue)}
          value={value}
          css={{ ml: '$space2' }}
          size="small"
        >
          {options.map((pageSize) => (
            <Select.Item value={pageSize} key={pageSize}>
              {pageSize}
            </Select.Item>
          ))}
        </Select>
      </Box>
    </Box>
  );
};

export const createPageSizeSelect = <
  FragmentType extends KeyType,
  QueryVariables extends ForwardPaginationArguments
>(
  usePaginationContext: UsePaginationContext<FragmentType, QueryVariables>
) => {
  const PageSizeSelectWithData: React.VFC<{ options: PageSizeOptions; css?: PicnicCss }> = ({
    options,
    ...rest
  }) => {
    const { usePaginationControls } = usePaginationContext();
    const [{ queryVariables }, dispatch] = usePaginationControls();

    return (
      <PageSizeSelect
        value={queryVariables.first || options[0]}
        onChange={(value) =>
          dispatch({
            type: PaginationControlsActionType.SET_VARIABLES,
            variables: { ...queryVariables, first: value },
          })
        }
        options={options}
        {...rest}
      />
    );
  };

  return PageSizeSelectWithData;
};
