import { Role, Scope } from '../types';

export { Role, Scope };

export enum Permission {
  SuperUserAccess = 'SuperUserAccess',
  MessagingOpsAccess = 'MessagingOpsAccess',
  ConciergeAccess = 'ConciergeAccess',
  ConciergeSettingsAccess = 'ConciergeSettingsAccess',
  ReferenceMfeAccess = 'ReferenceMfeAccess',
  AdminUIAccess = 'AdminUIAccess',
  VersionDialogAccess = 'VersionDialogAccess',
  DeveloperBannerAccess = 'DeveloperBannerAccess',
  AccountManagementAccess = 'AccountManagementAccess',
  FeatureFlagsManagementAccess = 'FeatureFlagsManagementAccess',
  SubscriberAuditAccess = 'SubscriberAuditAccess',
  ToggleSuperUserView = 'ToggleSuperUserView',
  ToggleFeatureFlagGlobally = 'ToggleFeatureFlagGlobally',
  ToggleFeatureFlagPercentageForClient = 'ToggleFeatureFlagPercentageForClient',
  AdvancedSftpFeaturesAccess = 'AdvancedSftpFeaturesAccess',
  EmailListMaintenanceAccess = 'EmailListMaintenanceAccess',
  EmailSuppressionClientAccess = 'EmailSuppressionClientAccess',
  ClientAdminAccess = 'ClientAdminAccess',
  ItSecurityAccess = 'ItSecurityAccess',
}

export interface RoleRule {
  roles: Role[];
}
export interface ScopeRule {
  scopes: Scope[];
}
export type Rule = RoleRule | ScopeRule;

export type Rules = Record<Permission, Rule>;

export const permissionRules: Rules = {
  [Permission.SuperUserAccess]: {
    roles: [Role.RoleSuperUser],
  },
  [Permission.MessagingOpsAccess]: {
    roles: [Role.RoleMessagingOps],
  },
  [Permission.ConciergeAccess]: {
    roles: [Role.RoleSuperUser, Role.RoleConcierge],
  },
  [Permission.ConciergeSettingsAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.AdminUIAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.ReferenceMfeAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.VersionDialogAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.DeveloperBannerAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.AccountManagementAccess]: {
    scopes: [Scope.ScopeAllAccounts],
  },
  [Permission.FeatureFlagsManagementAccess]: {
    roles: [Role.RoleAttentiveAccount, Role.RoleE2ETestUser],
  },
  [Permission.SubscriberAuditAccess]: {
    scopes: [Scope.ScopeAuditSubscriptions],
  },
  [Permission.ToggleSuperUserView]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.ToggleFeatureFlagGlobally]: {
    roles: [Role.RoleEngineering, Role.RoleProductManagement],
  },
  [Permission.ToggleFeatureFlagPercentageForClient]: {
    roles: [Role.RoleEngineering, Role.RoleProductManagement, Role.RoleSuperUser],
  },
  [Permission.AdvancedSftpFeaturesAccess]: {
    roles: [Role.RoleEngineeringSolutions],
  },
  [Permission.EmailListMaintenanceAccess]: {
    roles: [Role.RoleAttentiveAccount],
  },
  [Permission.EmailSuppressionClientAccess]: {
    roles: [Role.RoleClientAccount, Role.RoleClientAdminAccount, Role.RoleClientMemberAccount],
  },
  [Permission.ClientAdminAccess]: {
    // Role.RoleClientAccount will be removed from this list once all client users have been backfilled to AdminAccount. For now, setting these additional roles for admin access so as to avoid using a feature flag.
    roles: [Role.RoleAttentiveAccount, Role.RoleClientAccount, Role.RoleClientAdminAccount],
  },
  [Permission.ItSecurityAccess]: {
    roles: [Role.RoleItSecurity],
  },
};
